
// import Home from './Pages/Home.vue';
// import Menu from './Pages/Menu.vue';
// import Item from './Pages/Item.vue';
// import Drinks from './Pages/Drinks.vue';
import Blank from './Pages/Blank.vue';

const routes = [
    { path: '/', component: Blank },
    // { path: '/menu/:cat/:subCat?', component: Menu },
    // { path: '/item/:cat/:item', component: Item },
    // { path: '/drinks', component: Drinks },
];

export default routes;




import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'

import routes from './Route.js';

Vue.config.productionTip = false
Vue.use(VueRouter);

 var router = new VueRouter({routes})

new Vue({
  render: h => h(App),
  router
}).$mount('#app');

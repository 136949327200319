<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      menu: {
        breakfast:{
          title: "All Day Breakfast",
          sku:"breakfast",
          childs:false,
          items: [
            {
              title:'Sini Breakfast - 1P',
              price:120,
              sku:'sini1p',
              link:'/item/breakfast/sini1p',
              desc:'<h3 class="mb-4 white">1 Person</h3><p class="white">Turkish traditional cheese(ezine, Mozzarella, Stick, feta, goat) fried egg served with your choice of sujusk or pastirma or Kavurma Honey, apricot jam, strawberry jam, Tahini Kaymak, butter, Cemen, Labneh, Moleses, green olive, black olives Selection of the finest homemade Turkish bakery Simit, Pogaca, Bazlama and roll bread Served with Turkish tea.</p>',
              images:[
                'img/menu/breakfast/20-min.jpg'
              ]
            },
            {
              title:'Sini Breakfast - 2P',
              price:120,
               link:'/item/breakfast/sini2p',
              desc:'<h3 class="mb-4 white">1 Person</h3><p class="white">Turkish traditional cheese(ezine, Mozzarella, Stick, feta, goat) fried egg served with your choice of sujusk or pastirma or Kavurma Honey, apricot jam, strawberry jam, Tahini Kaymak, butter, Cemen, Labneh, Moleses, green olive, black olives Selection of the finest homemade Turkish bakery Simit, Pogaca, Bazlama and roll bread Served with Turkish tea.</p>',
              images:[
                'img/menu/breakfast/20-min.jpg'
              ]
            },
            {
              title:'Sini Breakfast - 4P',
              price:120,
               link:'/item/breakfast/sini4p',
              desc:'<h3 class="mb-4 white">1 Person</h3><p class="white">Turkish traditional cheese(ezine, Mozzarella, Stick, feta, goat) fried egg served with your choice of sujusk or pastirma or Kavurma Honey, apricot jam, strawberry jam, Tahini Kaymak, butter, Cemen, Labneh, Moleses, green olive, black olives Selection of the finest homemade Turkish bakery Simit, Pogaca, Bazlama and roll bread Served with Turkish tea.</p>',
              images:[
                'img/menu/breakfast/20-min.jpg'
              ]
            },

          ]

        },
        lnd:{
          title: "Lunch & Dinner",
        },
        fto:{
          title: "From The Oven",
        },
        desserts:{
          title: "Desserts",
        },
        croissants:{
          title: "Croissants",
        },
      },
    };
  },
};
</script>

<template>
  <div>
      <h1>500 Internal Server Error</h1>
  </div>
</template>

<script>
export default {
  name: "Blank",
};
</script>
